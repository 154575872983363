<template>
  <div>
    <section class="container mt-5" style="padding: 0;">
      <v-row class="d-flex justify-center align-center">
        <h5 class="mb-5 mt-5"><strong>Ficha Técnica</strong></h5>
      </v-row>
    </section>
    <section class="container">
      <v-row class="justify-center align-center">
        <v-col cols="12" md="7">
          <v-simple-table class="pa-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Coordenação Geral:</strong></td>
                  <td>Laís Jabace Maia</td>
                </tr>
                <tr>
                  <td><strong>Coordenação executiva:</strong></td>
                  <td>Paula Pflüger Zanardi</td>
                </tr>
                <tr>
                  <td><strong>Coordenação de geoprocessamento:</strong></td>
                  <td>Priscila Aparecida da Rocha Honorato</td>
                </tr>
                <tr>
                  <td><strong>Referência técnica: </strong></td>
                  <td>Gabriel Mateus Silva Leite</td>
                </tr>
                <tr>
                  <td><strong>Validação:</strong></td>
                  <td>Ana Paula Silva Ferreira, Karine de Jesus Santos</td>
                </tr>
                <tr>
                  <td><strong>Análise de dados: </strong></td>
                  <td>Pedro Paulo Barros Gonçalves</td>
                </tr>
                <tr>
                  <td><strong>Tecnologia de informação: </strong></td>
                  <td>Luigi Martins</td>
                </tr>
                <tr>
                  <td><strong>Apoio administrativo: </strong></td>
                  <td>Letícia Mansur</td>
                </tr>
                <tr>
                  <td><strong>Apoio jurídico:</strong></td>
                  <td>Carla Magalhães, Raphaela Sena</td>
                </tr>
                <tr>
                  <td><strong>Comunicação e redes sociais:</strong></td>
                  <td>Ellen Barros, Wan Campos, Wigde Arcângelo</td>
                </tr>
                <tr>
                  <td><strong>Curadoria de fotos:</strong></td>
                  <td>
                    Ana Paula Silva Ferreira, Edimar Antônio da Silva, Ellen
                    Barros, Ludmilla Guimarães Novaes de Oliveira, Teresa Chaves
                    Silva, Wan Campos.
                  </td>
                </tr>
                <tr>
                  <td><strong>Revisão de texto e copidesque: </strong></td>
                  <td>Gabriela Amorim</td>
                </tr>
                <tr>
                  <td><strong>Identidade visual e ilustrações: </strong></td>
                  <td>EAS Design - Elisa Albino Smania</td>
                </tr>
                <tr>
                  <td><strong>UX/UI: </strong></td>
                  <td>
                    BoomerOn - Marcela de Oliveira Baptista, Gustavo Escudeiro e
                    Júlia Barreto
                  </td>
                </tr>
                <tr>
                  <td><strong>Desenvolvimento:</strong></td>
                  <td>
                    GSF Tecnologia - Guilherme Felix, André Luiz Teixeira Leite
                    Campos, Gustavo Henrique Soares Ferreira
                  </td>
                </tr>
                <tr>
                  <td><strong>Audiovisual: </strong></td>
                  <td>Calumbi Pesquisa e Produção Cultural</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-5" style="padding: 0;">
      <v-row class="d-flex justify-center align-center">
        <h5 class="mb-5 mt-5"><strong>Agradecimentos</strong></h5>
      </v-row>

      <v-row class="justify-center align-center">
        <v-col cols="11" md="7">
          <v-card class="pa-6">
            <p>
              Dedicamos esse projeto às milhares de vítimas. Se a lama de
              rejeitos atravessou suas vidas e soterrou tanto, desde novembro de
              2015 essas pessoas são incansáveis em lutar pela reparação justa e
              integral e pela não repetição desse desastre-crime.
            </p>
            <p style="margin-bottom: 0;">
              Agradecemos a toda a equipe e àqueles que em algum momento
              estiveram na Assessoria Técnica Independente aos Atingidos e
              Atingidas de Mariana - MG. Em especial, às atuais coordenações e
              assessores do Cadastro, Assessoria Jurídica e PIPAM, que
              disponibilizaram energia e tempo em meio às várias atividades para
              a realização desse projeto-sonho.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-5" style="padding: 0;">
      <v-row class="d-flex justify-center align-center">
        <h5 class="mb-5 mt-5"><strong>Realização</strong></h5>
      </v-row>

      <v-row class="justify-center align-center">
        <v-col cols="11" md="7">
          <v-card class="pa-6">
            <p class="text-center">
              <strong>Cáritas Brasileira Regional Minas Gerais</strong>
            </p>

            <v-simple-table class="pa-5">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><strong>Coordenação Colegiada: </strong></td>
                    <td>
                      Anna Crystina Alvarenga, Carla Magalhães Carvalho, Samuel
                      Silva
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Coordenação de Projetos:</strong></td>
                    <td>Rodrigo Pires Vieira</td>
                  </tr>
                </tbody></template
              ></v-simple-table
            >
          </v-card>
        </v-col>
      </v-row>
    </section>

    <section
      class="container d-flex justify-center align-center flex-column mt-5"
    >
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="6" md="2" class="d-flex justify-center align-center">
          <img width="50%" src="../assets/logo_eas.png" alt="" />
        </v-col>
        <v-col cols="6" md="2" class="d-flex justify-center align-center">
          <img src="../assets/logo_boomer_on.png" alt="" />
        </v-col>
        <v-col cols="6" md="3" class="d-flex justify-center align-center">
          <img class="ml-5" src="../assets/logo_gsf.png" alt="" />
        </v-col>
        <v-col cols="6" md="2" class="d-flex justify-center align-center">
          <img width="50%" src="../assets/logo_calumbi.png" alt="" />
        </v-col>
      </v-row>

      <v-row class="container d-flex justify-center align-center rodape">
        <v-col
          cols="6"
          md="3"
          class="d-flex justify-center align-center rodape"
        >
          <img src="/logos/logo-caritas-main-preto.png" alt="" />
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="d-flex justify-center align-center rodape"
        >
          <img src="/logos/territorio-atingido-logo-nome-verde.png" alt="" />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  color: #e06919;
}

.router-link-exact-active {
  background-image: url("../assets/link_bottom.png");
  width: 50%;
}
section {
  padding-top: 2em;
  padding-bottom: 2em;
}
h5,
span,
a {
  color: #e06919;
}

p {
  font-size: 0.7em;
}

.text__box {
  max-width: 80%;
}

.rodape img {
  width: 350px;
}

.rodape span {
  font-size: 0.7em;
  text-align: center;
}

@media (max-width: 600px) {
  section {
    padding-bottom: 0;
    padding-top: 0;
  }
}
</style>
